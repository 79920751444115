import React, { useCallback, useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { ENQUEUE } from "../../store/actions/notifyActions";
import PasswordValidation from "../Login/Validation/PasswordValidation"
import { PRIVATE_ROUTES,  ROUTES } from "../../constant/routes";
import { resetPasswordInitialValues } from "../../formik/auth/InitialValues";
import { resetPassValidationSchema } from "../../formik/auth/ValidationSchema";
import { LoginWrapper } from "../../components/Common/Layouts/Layout";
import { Button, MainTitle } from "../../components/Common";
import { CheckIcon } from "../../assets/svg";
import {
  BACK,
  CHANGE_PASSWORD,
  GO_TO_HOME,
  GO_TO_SIGN_IN,
  LOGIN_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  SUBMIT,
} from "../../constant/Login"
import { ThemeContext } from "../../components/Common/Theme/Context"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ResetPassword = (props) => {
  const { theme } = useContext(ThemeContext);
  const history = useHistory()
  const [isConfirmed, setIsConfirmed] = useState(!true);
  const [userConfirmError, setUserConfirmError] = useState(null);
  const dispatch = useDispatch();
  const [code, setCode] = useState(null);
  const [, setDisable] = useState(true);
  const [passwordVal, setPasswordVal] = useState("");
  const [confirmPasswordVal, setConfirmPasswordVal] = useState("");
  const { actions, cognitoUser,  ...rest } = props;

  useEffect(() => {
    const url = new URL(window.location.href);
    const urLcode = url.searchParams.get("key");
    const parts = urLcode?.split("__");
    let params;

    try {
      params = JSON.parse(atob(parts[0]));
    } catch (err) {
      console.log('Error parsing',err)
      if (!urLcode && !cognitoUser) {
        dispatch({
          type: ENQUEUE,
          payload: {
            message: LOGIN_ERROR.wrongConfirmation,
          },
        });
        return;
      } else {
        // history.push(ROUTES.HOME);
        // dispatch({
        //   type: ENQUEUE,
        //   payload: {
        //     message: LOGIN_ERROR.wrongConfirmation,
        //   },
        // });
        // window.location.href="/";
      }
      return;
    }
    const confirmationCode = parts[1];
    setCode({ params, confirmationCode });
  }, [cognitoUser, dispatch]);

  const passwordValidation = useCallback((ruleIndex) => {
    const rules = [
      () => passwordVal.length < 10,
      () => !hasUpperCase(passwordVal),
      () => !hasLowerCase(passwordVal),
      () => !hasNumbers(passwordVal),
      () => !hasSpecialSymbols(passwordVal),
      () => passwordVal !== confirmPasswordVal || !passwordVal,
    ];
    if (ruleIndex === "all") {
      return rules.map((el) => el()).includes(true);
    }
    return rules[ruleIndex]();
  }, [passwordVal, confirmPasswordVal]);

  const hasLowerCase = (str) => /[a-z]/.test(str);
  const hasUpperCase = (str) => /[A-Z]/.test(str);
  const hasNumbers = (str) => /[0-9]/.test(str);
  const hasSpecialSymbols = (str) =>
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(str);
  
  useEffect(() => {
    if (!passwordValidation("all")) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [confirmPasswordVal, passwordValidation]);

  const onSubmit = async (values) => {
    if (code) {
      const { params, confirmationCode } = code;
      actions.auth
        .confirmPassReset({
          username: params.userName,
          code: confirmationCode,
          newPassword: values.password,
        })
        .then(() => {
          setIsConfirmed(true);
        })
        .catch((err) => {
          setIsConfirmed(false);
          setUserConfirmError(err.message);
        });
    } else {
      actions.auth
        .completeNewPasswordChallenge({
          password: values.password,
        })
        .then(() => {
          window.location.href="/"
          setIsConfirmed(true);
        })
        .catch((err) => {
          setIsConfirmed(false);
          setUserConfirmError(err.message);
        });
    }
  };

  return (
    <LoginWrapper loading={null} theme={theme}>
      {code || cognitoUser ? (
        <div className="login-container top-login-row">
          <div
            className="login-form-column extra-tablet-space"
            style={{ alignItems: "center" }}
          >
              <MainTitle classNames="m-8" title={RESET_PASSWORD} />
            <Formik
              initialValues={resetPasswordInitialValues}
              onSubmit={onSubmit}
              validationSchema={resetPassValidationSchema}
            >
              {({ handleSubmit, touched, errors, setFieldValue } = rest) => (
                <form style={{width:'70%'}} onSubmit={handleSubmit}>
                  {!isConfirmed && !userConfirmError ? (
                    <div
                      className="login-form-column"
                      style={{ alignItems: "center"}}
                    >
                     
                      <PasswordValidation
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        setPasswordVal={setPasswordVal}
                        setConfirmPasswordVal={setConfirmPasswordVal}
                        passwordValidation={passwordValidation}
                      />
                      <div className="login-container btn-container" style={{gap: 10, flexDirection: 'row-reverse'}}>
                        <Button
                           className="w100"
                          containerClassName=""
                          type="submit"
                        >
                          {SUBMIT}
                        </Button>
                        <Button
                          type="button"
                          className="w100"
                          containerClassName=""
                          onClick={() => {
                            history.push(ROUTES.LOGIN);
                            localStorage.removeItem("group");
                          }}
                        >
                          {BACK}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="login-form-column centered">
                      {isConfirmed ? <CheckIcon /> : <></>}
                      {userConfirmError ? (
                        <div className="exclamation-icon-container" />
                      ) : (
                        <></>
                      )}
                      <div className="info input-title m-20 text-center">
                        {isConfirmed ? <>{CHANGE_PASSWORD}</> : <></>}
                        {userConfirmError ? (
                          <>
                            <h2 className="m-0">{RESET_PASSWORD_ERROR}</h2> <br />
                            {userConfirmError}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {code ? (
                        <div className="login-container btn-container">
                          <Button
                            containerClassName=""
                            className={""}
                            onClick={() => {
                              history.push(ROUTES.LOGIN);
                            }}
                          >
                            {GO_TO_SIGN_IN}
                          </Button>
                        </div>
                      ) : (
                        <div className="login-container btn-container">
                          <Button
                            containerClassName=""
                            className={""}
                            onClick={() => {
                              history.push(PRIVATE_ROUTES.HOME);
                            }}
                          >
                            {GO_TO_HOME}
                          </Button>
                        </div>
                      )}
                    </div>
                 )} 
                </form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div
          className="login-form-column centered"
          style={{ minWidth: "unset" }}
        >
          <div className="title with-underline">
            <h1>
              <span>ERROR 405</span>
            </h1>
          </div>
          <div className="info" style={{ textAlign: "center" }}>
            <h3>Page not Found!</h3>
            <div className="login-container btn-container">
              <Button
                containerClassName=""
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                  localStorage.removeItem("group");
                }}
              >
                {GO_TO_SIGN_IN}
              </Button>
            </div>
          </div>
        </div>
      )}
    </LoginWrapper>
  );
};
export default ResetPassword;

ResetPassword.propTypes = {
  actions: PropTypes.object,
  cognitoUser: PropTypes.object,
  history: PropTypes.object,
};
