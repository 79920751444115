/* eslint-disable no-case-declarations */
import moment from "moment";
import { ENQUEUE,DEQUEUE } from "../actions/notifyActions";

const initialState = [];

function notifyReducer(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE:
      const expiryTime = moment();
      return [
        ...state,
        {
          expiryTime,
          id:parseInt(Math.random() * 1000),
          payload: action.payload || {},
        },
      ];

    case DEQUEUE:
 
    return state.filter((item)=> item.id !== action.id)

    default:
      return state; // Return the current state instead of resetting
  }
}

export default notifyReducer;
