import axios from "axios";
import { API_GATEWAY_URL } from "../../config";
import { dispatcher } from "../../store";
import {
  getUserDetail,
  updateAdminCredit,
  updateCredit,
  userPhoneUpdate,
} from "../../services";

export const getUsernameByEmail = (email) =>
  function (dispatch) {
    return new Promise(async (resolve, reject) => {
      return await axios
        .post(`${API_GATEWAY_URL}/user/get-username`, {
          email: email,
        })
        .then((result) => {
          resolve(result.data.result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export function updateCreditAdd(data) {
  return async function (dispatch) {
    return new Promise(async (resolve, reject) => {
      await updateCredit(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function userPhoneNumberAdd(data) {
  return async function (dispatch) {
    return new Promise(async (resolve, reject) => {
      await userPhoneUpdate(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function getUserDetails(data) {
  return async function (dispatch) {
    return new Promise(async (resolve, reject) => {
      await getUserDetail(data)
        .then((response) => {
          dispatch(
            dispatcher("SET_CREDIT", {
              payload: response?.data?.data,
            })
          );
          resolve(response);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  };
}

export function setCreditCountDetails(data) {
  return async function (dispatch) {
    return new Promise(async (resolve, reject) => {
      dispatch(
        dispatcher('SET_CREDIT_COUNTS', {
          payload: data?.data
        })
      );
      resolve(true);
    });
  };
}

// Notification
export function updateCreditAdmin(data) {
  return async function (dispatch) {
    return new Promise(async (resolve, reject) => {
      await updateAdminCredit(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  };
}
