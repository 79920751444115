import React, { useState } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { ENQUEUE } from "../../store/actions/notifyActions";
import { emailValidationSchema } from  "../../formik/auth/ValidationSchema"
import { LoginWrapper } from "../../components/Common/Layouts/Layout";
import { Button, Input as LoginInput, MainTitle, Spinner } from "../../components/Common";
import { CheckIcon } from "../../assets/svg";
import {
  EMAIL_RESET_LINK,
  BACK,
  LOGIN_ERROR,
  SUBMIT,
  FORGOT_PASSWORD,
} from "../../constant/Login";
import "./forgotpassword.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ForgotPassword = (props) => {
  const { actions,  ...rest } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    setLoading(true);
   await actions.user.getUsernameByEmail(values.email).then((result) => {
    if (result) {

      if(result.Enabled === false) {

        dispatch({
          type: ENQUEUE,
          payload: {
            message: LOGIN_ERROR.userDisabled,
          },
        });
        setLoading(false);

        return;

      } else if(result.UserStatus === "FORCE_CHANGE_PASSWORD") {

        dispatch({
          type: ENQUEUE,
          payload: {
            message: LOGIN_ERROR.temporaryPasswordExpired,
          },
        });
        setLoading(false);
        return;

      } else if(result.Username && result.Enabled) {

            actions.auth
            .sendResetPassRequest({ Username: result.Username })
            .then(() => {
              setLoading(false);
              setIsCompleted(true);
            })
            .catch((err) => {
              setLoading(false);
              dispatch({
                type: ENQUEUE,
                payload: {
                  message: `Can't send password reset request ${
                    err.message || "Network error"
                  }`,
                },
              });
            });

      } else {

        dispatch({
          type: ENQUEUE,
          payload: {
            message: LOGIN_ERROR.userNotExist,
          },
        });
        setLoading(false);
        return;

      }
    
    }

   }).catch((error) => {
    console.log('Error: ', error)
    if(error){
      dispatch({
        type: ENQUEUE,
        payload: {
          message: LOGIN_ERROR.userNotExist,
        },
      });
      setLoading(false);
      return;
    }
   });
   

    
   
  };

  return (
    <LoginWrapper loading={null}>
      <div className="login-container top-login-row">
        {!isCompleted ? (
          <div
            className="login-form-column extra-tablet-space"
            style={{ alignItems: "center" }}
          >
            <MainTitle classNames={"m-8"} title={FORGOT_PASSWORD} />
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={onSubmit}
              validationSchema={emailValidationSchema}
            >
              {({ handleSubmit, setFieldValue, errors, touched } = rest) => (
                <form className="w100" onSubmit={handleSubmit}>
                  <LoginInput
                    label=""
                    class="nd-big-inp lng"
                    error={touched.email && errors.email}
                    autofocus
                    onChange={(value) => {
                      setFieldValue("email", value);
                    }}
                    onEnter={() => {
                      if (!errors.email) {
                        handleSubmit();
                      }
                    }}
                  >
                    <h2 className="info input-title">
                        Enter your registered email address to reset your password.
                      <br />
                    </h2>
                  </LoginInput>
                  {loading ? (
                    <div className="forgot-password-spinner">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="forgot-btn-container">
                      <Button
                        type="button"
                        className="btn-medium"
                        containerClassName=""
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {BACK}
                      </Button>
                      <Button containerClassName="" className="btn-medium" type="submit">
                        {SUBMIT}
                      </Button>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div
            className="login-form-column centered"
          >
          
            <MainTitle classNames={"m-8"} title={FORGOT_PASSWORD} />
            <div className="mt-8">

            <CheckIcon />
            </div>
            <h2 className="info input-title m-20 text-center" >
              {EMAIL_RESET_LINK}
            </h2>
            <div className="login-container btn-container">
              <Button
                type="button"
                className="btn-small m-8"
                containerClassName=""
                onClick={() => {
                  history.goBack();
                }}
              >
                {BACK}
              </Button>
            </div>
          </div>
        )}
      </div>
    </LoginWrapper>
  );
};
export default ForgotPassword;

ForgotPassword.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object,
};
