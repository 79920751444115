import * as yup from "yup";

export const emailValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export const resetPassValidationSchema = yup.object({
  password: yup
    .string("Enter your new password")
    .required("New Password is required"),
  confirmPassword: yup
    .string("Confirm new password")
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
