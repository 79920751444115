/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import store from "../../store";
import { ENQUEUE } from "./notifyActions";

const notify_ = (message, type, dispatch, yesCallBack, noCallBack) => {

  const t = [...store.getState().service.tmpNotifications];

  if (t.some((o) => message === o.message)) {
    return;
  }

  if (t.length > 10) return;

  const n = {
    message,
    type,
    className: "hidden",
    id: "" + Math.random(),
    yesCallBack,
    noCallBack,
  };

  t.push(n);
  dispatch({ type: "UPDATE_SERVICE_STATE", tmpNotifications: t });

  const refreshState = () => {
    dispatch({
      type: "UPDATE_SERVICE_STATE",
      tmpNotifications: [...store.getState().service.tmpNotifications],
    });
  };

  setTimeout(() => {
    n.className = "";
    refreshState();
  }, 1);
  
  setTimeout(() => {
    n.className = "removing";
    refreshState();

    setTimeout(() => {
      const tmpNotifications = store
        .getState()
        .service.tmpNotifications.filter((v) => v.id !== n.id);
      dispatch({ type: "UPDATE_SERVICE_STATE", tmpNotifications });
    }, 1000);
  }, 6000);

  setTimeout(() => {
    dispatch({
      type: ENQUEUE,
      payload: {
        message,
      },
    });
  }, 1000);
};

export const notify = (message) =>
  function (dispatch) {
    setTimeout(() => {
      notify_(message, "info", dispatch);
    }, 3000);
  };

export const noInernetConnection = (val) =>
  function (dispatch) {
    setTimeout(() => {
      dispatch({ type: "SET_NO_CONNECTION" });
    });
  };

export const notifyError = (message) =>
  function (dispatch) {
    setTimeout(() => {
      notify_(message, "error", dispatch);
    }, 3000);
  };
