/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DEQUEUE } from "../../../store/actions/notifyActions";

const anchorOrigin = {
  vertical: "top",
  horizontal: "right",
};

const AutoKillableSnackBar = ({ index,id, expiryTime, payload: { message } }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (moment(expiryTime).add(5, "seconds").isSameOrBefore(moment())) {
       
        dispatch({
          type: DEQUEUE,
          expiryTime,
          id
        });
      
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, expiryTime,id]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      key={index}
      open={open}
      message={message}
      anchorOrigin={anchorOrigin}
      action={action}
    />
  );
};

const SnackBarComponent = () => {
  const notifyState = useSelector((state) => state.notifyreducer);
  return (
    <>
      {notifyState.map((text, index) => (
        <AutoKillableSnackBar  key={`${text}-${index}`} {...text} index={index} />
      ))}
    </>
  );
};

export default SnackBarComponent;

AutoKillableSnackBar.propTypes = {
  index: PropTypes.number,
  expiryTime: PropTypes.object,
  payload: PropTypes.object,
};
