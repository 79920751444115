/* eslint-disable */
import React, { Component, forwardRef } from "react";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Input extends Component {
  constructor(props) {
    super(props);
    this.inputId = `input-${Math.random()}`;
  }

  render() {
    const inputStyle = this.props.inputStyle || {};
    return (
      <div
        className={`login-field border-bottom input-group ${this.props.class} ${
          this.props.error ? "error" : ""
        }`}
        style={this.props.style || {}}
      >
        <label htmlFor={this.inputId}>
          {this.props.label || ""}
          {this.props.children || ""}
          {this.props.required ? <span className="red-color">*</span> : ""}
          {this.props.sublabel ? (
            <span className="sub-lable">{this.props.sublabel}</span>
          ) : (
            <></>
          )}
          {this.props.verifying ? (
            <FontAwesomeIcon icon="spinner" spin={true} />
          ) : null}
        </label>
        {this.props.selectArr ? (
          <select value={this.props.value} onChange={this.props.onChange}>
            {this.props.selectArr.map((opt, i) => (
              <option key={i}>{opt}</option>
            ))}
          </select>
        ) : this.props.mask ? (
          <InputMask
            mask={this.props.mask}
            maskChar={this.props.maskChar}
            value={this.props.value}
            placeholder={this.props.placeholder}
            alwaysShowMask
            onChange={(e) => {
              this.props.onChange(e.target.value);
            }}
          />
        ) : (
          <input
            className="input-mask"
            ref={this.props.innerRef}
            id={this.inputId}
            style={inputStyle}
            type={this.props.type || "text"}
            autoFocus={this.props.autofocus}
            autoComplete={this.props.noAutocomplete ? "new-password" : ""}
            name={this.props.label ? this.props.label.toLowerCase() : ""}
            onChange={(e) => {
              this.props.onChange(e.target.value);
            }}
            onKeyPress={this.props.onKeyPress}
          />
        )}
        <div className="error-label">{this.props.error}</div>
      </div>
    );
  }
}

const LoginInput = forwardRef((props, ref) => (
  <Input innerRef={ref} {...props} />
));

export default LoginInput;
