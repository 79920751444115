// title constraints
export const APP_TITLE = "Fractal";
export const SITE_DATA = "Site Data";
export const MARKET_DATA = "Market Data";

// h2 constraints
export const LOGIN = "Login";
export const REPORTS_DASHBOARD = "Reports Dashboard";
export const REPORTS_CSV_UPLOAD = "Upload Report (CSV Only)";
export const CSV_IMPORT_TABLE = "Report Preview";

// label constraints

export const START = "Start";
export const STOP = "Stop";
export const FROM = "From";
export const TO = "To";
export const NO_DATA_FOUND = "No data found";
export const ERROR = "Error";

export const REFRESH_RATE_MS = 60000;

export const REPORT_ERROR_MESSAGE = {
  NETWORK_ERROR: 'Network issue. Please Check your connection.',
  INVALID_DATA:NO_DATA_FOUND,
  ERROR : "Error",
  INVALID_DATE_RANGE: 'Please select a valid date range ',
  NO_DATA_AVAILABLE: NO_DATA_FOUND,
  INTERNAL_SERVER_ERROR:'Internal server error. Please try again.',
  INVALID_FILE:'Please select a valid file.',
  UPLOAD_REQUIRED_FIELD:'Can\'t upload. Required fields are missing',

};

// button constraints
export const RESET = 'Reset';
export const UPLOAD = 'Upload';
export const DOWNLOAD_SAMPLE_CSV = 'Download Sample CSV';

//Formik Validation Messages Constraints
export const IS_CSV = 'csv is required';
export const IS_SELECT_CSV = 'Select csv file';

// CSV import constraints
export const SAMPLE_CSV_HEADERS = ["time_utc",	"time_local",	"date_local","BESS MW","BESS MWh","RTLMP","RTM Revenue","RTM Cost","DAM AWARD MWh","DALMP","DALMP Revenue","DALMP Settlement","ECRS AWARD MW","ECRS Price","ECRS Revenue","RRS AWARD MW","RRS Price","RRS Revenue"]
export const SAMPLE_CSV_DATA = ["11/8/24 6:00",	"11/8/24 0:00",	"2024-11-08","-3.04","-0.25","26.61","0","-7","0","29.51","0","0","0","0","0","0","0","0"]
export const UPLOAD_MESSAGE_CSV = `Drag 'n' drop your csv file here, or click to select file`;

export const REQUIRED_CSV_COLUMNS = ["time_utc", "time_local", "date_local"]; // Replace with your actual expected column names


/**
 * Parses a local date-time string ("YYYY-MM-DDTHH:mm") to a Date object.
 * @param {string} dateTimeStr - Local date-time string.
 * @returns {Date} - Date object in local time.
 */
export const parseLocalDateTime = (dateTimeStr) => {
  const [datePart, timePart] = dateTimeStr.split("T");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hours, minutes] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes);
};

/**
 * Formats a Date object to a "YYYY-MM-DDTHH:mm" string.
 * @param {Date} date - Date object to format.
 * @returns {string} - Formatted date-time string.
 */
export const formatLocalDateTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

/**
 * Gets the current date-time as a "YYYY-MM-DDTHH:mm" string.
 * @returns {string} - Current date-time string.
 */
export const getCurrentDateTime = () => {
  return formatLocalDateTime(new Date());
};

/**
 * Gets the date-time string from two days ago in "YYYY-MM-DDTHH:mm" format.
 * @returns {string} - Date-time string two days prior.
 */
export const getStartDateTime = () => {
  const date = new Date();
  date.setDate(date.getDate() - 2); // Subtract 2 days
  return formatLocalDateTime(date);
};

/**
 * Converts a local date-time string to UTC in "YYYY-MM-DDTHH:mm:ssZ" format.
 * @param {string} localTimeStr - Local date-time string.
 * @returns {string} - UTC date-time string.
 */
export const toUTC = (localTimeStr) => {
  const localDate = parseLocalDateTime(localTimeStr);
  const utcYear = localDate.getUTCFullYear();
  const utcMonth = String(localDate.getUTCMonth() + 1).padStart(2, "0");
  const utcDay = String(localDate.getUTCDate()).padStart(2, "0");
  const utcHours = String(localDate.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(localDate.getUTCMinutes()).padStart(2, "0");
  const utcSeconds = String(localDate.getUTCSeconds()).padStart(2, "0");
  return `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}:${utcSeconds}Z`;
};

export const toUnix  = (dateString) => {

  return new Date(dateString).valueOf()

}

export const TIME_ZONE = 'America/Chicago';